import axios from "axios";
import React,{useEffect,useState} from "react";
import Sidebar from "./sidebar";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Overlay from "react-overlay-component";
import NewInvoice from "./newInvoice";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';
import InvoiceInfo from "./invoiceInfo";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FontAwesome from 'react-fontawesome';
import config from "../config";

function Dashboard(){
    const[data,setData] = useState([]);
    const [showForm,setShowForm] = useState(false);
    const[currentPage,setCurrentPage]=useState(1);
    const [pagePostsLimit,setpageSize] = useState(10);
    const [modalOpened,setmodalOpened] = useState(false);
    const [modalOpened3,setmodalOpened3] = useState(false);
    const [modalOpened4,setmodalOpened4] = useState(false);
    const[selectedInvoice,setSelectedInvoice] = useState({});
    const [filter,setFilter] = useState("");
    const [modal, setModal] = useState(false);
    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [records,setRecords] = useState(0);
    const [search,setSearch] = useState("");
    const animate = true;
    const navigate = useNavigate();
    const [pending,setPending] = useState("");
    const [verified,setVerified] = useState("");
    const [rejected,setRejected] = useState("");
    const [signed,setSigned] = useState("");
    const [createDate,setCreateDate] = useState("");
    const [verifyDate,setVerifyDate] = useState("");
    const [signDate,setSignDate] = useState("");

    const[invoiceNo,setInvoiceNo] = useState("");
    const[clientName,setClientName] = useState("");
    const[quantity,setQuantity] = useState(0);
    const[amount,setAmount] = useState(0);
    const[invoiceDate,setInvoiceDate] = useState(moment().format());
    const[verificationDate,setVerificationDate] = useState(null);
    const[signatureDate,setSignatureDate] = useState(null);
    const[dueDate,setDueDate] = useState("");
    const[remarks,setRemarks] = useState("");
    const[img,setImg] = useState("");

    const[order,setOrder] = useState("");
    const[sortBy,setSortBy] = useState("");

    const setFile = (e)=>{
        console.log("Entered");
        console.log(e.target.files[0].name);
        setImg(e.target.files[0].name);
    }

    const setCheckBox = (e)=>{ 
        console.log("Entered");
        const value = e.target.value;
        if(e.target.id=="1"){
            if(pending) setPending("");
            else setPending("pending");
        } 
        if(e.target.id=="2") {
            if(verified) setVerified("");
            else setVerified("verified");
        }
        if(e.target.id=="3"){
            if(signed) setSigned("");
            else setSigned("signed");
        } 
        if(e.target.id=="4") {
            if(rejected) setRejected("");
            else setRejected("rejected");
        }
        if(e.target.id=="11"){
            setOrder(value);
            setSortBy("createdAt");
        } 
        if(e.target.id=="12"){
            setOrder(value);
            setSortBy("createdAt");
        }
        if(e.target.id=="13"){
            setOrder(value);
            setSortBy("verificationDate");
        }
        if(e.target.id=="14"){
            setOrder(value);
            setSortBy("verificationDate");
        }
        if(e.target.id=="15"){
            setOrder(value);
            setSortBy("signatureDate");
        }
        if(e.target.id=="16"){
            setOrder(value);
            setSortBy("signatureDate");
        }
    }

    const onChangeHandler = (e)=>{
        const value = e.target.value;
        if(e.target.id=="1") setInvoiceNo(value);
        if(e.target.id=="2") setClientName(value);
        if(e.target.id=="3") setQuantity(value);
        if(e.target.id=="4") {
            console.log("Entered");
            setAmount(value) ;
            console.log(amount);
        }
        if(e.target.id=="5") setInvoiceDate(value);
        if(e.target.id=="6") setVerificationDate(moment(value).format());
        if(e.target.id=="7") setSignatureDate(moment(value).format());
        if(e.target.id=="8") setRemarks(value);
        if(e.target.id=="9"){
            console.log(moment(value).format());
            setDueDate(value);
            console.log(dueDate);
        } 
        if(e.target.id=="10") {
            if(value==""){
                getData()
            }
            setSearch(value);
            applySearch();
        }
    }

    function submitHandler(){
        console.log(amount);
        console.log(dueDate);
        axios.post(`${config.serverURL}/auth/invoiceData/create`,{
           invoiceNo : invoiceNo,
           clientName : clientName,
           quantity : quantity,
           amount : amount,
           dueDate : moment(dueDate).format(),
           invoiceDate : invoiceDate,
           verificationDate : verificationDate,
           signatureDate : signatureDate,
           remarks : remarks,
           createdDate : moment().subtract(10, 'days').calendar()
        },{headers:{"token" : localStorage.getItem("token"),"userId" : localStorage.getItem("id")}})
        .then((res)=>{
            if(res.data.statusCode==200)
            {
                toast("Sucessfully Submitted",{
                    position:"bottom-center",
                    type:"success"
                });
                getData();
                closeOverlay();

            }
            if(res.data.statusCode==400)
            {
                toast("Please Fill Out All Fields",{
                    position:"bottom-center",
                    type:"success"
                });
            }
        })
        .catch((err)=>{
            console.log(err);
            toast(err.response.data.message,{
                position:"bottom-center",
                type:"error"
            })
        })
        
   }

    const enableForm=()=>{
        setmodalOpened(true);
        console.log(modalOpened);
    }

    const closeOverlay = ()=>{
        setmodalOpened(false);
        // setmodalOpened2(false);
        setmodalOpened3(false);
        setmodalOpened4(false);
        console.log(currentPage);
        getData(currentPage);
    }

    const getInvoiceInfoForm=(obj)=>{
        console.log(obj);
        setShowForm(true);
        setmodalOpened4(true);
        console.log(modalOpened4);
        setSelectedInvoice(obj);
    }

    function applyFilters (pageNumber){
        console.log("Entered");
        console.log("create" , createDate);
        console.log("verify" , verifyDate);
        console.log("sign", signDate);
        
        axios.get(`${config.serverURL}/auth/invoiceData/getAll?status=${pending}&status=${verified}&status=${rejected}&status=${signed}&createdDate=${createDate}&verifiedDate=${verifyDate}&signedDate=${signDate}&pageSize=10&page=${pageNumber || 1}`)
        .then((res)=>{
            setData(res.data.data);
            console.log(res.data.data);
            setModalOpen1(false);
        })
        axios.get(`${config.serverURL}/auth/invoiceData/count?status=${pending}&status=${verified}&status=${rejected}&status=${signed}&createdDate=${createDate}&verifiedDate=${verifyDate}&signedDate=${signDate}`)
        .then((res)=>{
            setRecords(res.data.data);
        })
    }

    const filterForm=()=>{
        setShowForm(true);
        setmodalOpened3(true);
        console.log(modalOpened3);
    }

    const onChangeHandler1 = (e)=>{
        const value = moment(e.target.value).format('L');
        if(e.target.id=="1") {
            setCreateDate(value);
        }
        if(e.target.id=="2") {
            setVerifyDate(value);
        }
        if(e.target.id=="3") {
            setSignDate(value);
        }
    }

    const handlePagination = (pageNumber)=>{
        setCurrentPage(pageNumber);
        getData(pageNumber);
    }

    // const changeFilter = ()=>{
    //     axios.get(`${config.serverURL}/auth/weighingData/getAll?page=${currentPage}&pageSize=${pagePostsLimit}&sortBy=slipNo&sortOrder=descending`,{headers : {token:localStorage.getItem("token")}},{params:{vehicleType : filter}})
    //     .then((res)=>{  
    //         console.log(res.data); 
    //         closeOverlay(); 
    //     })
    // }

    function applySearch(){
        axios.get(`${config.serverURL}/auth/invoiceData/search/${search}`)
        .then((res)=>{
            setData(res.data.data);
        })
    }

    function applySort(pageNumber){
        axios.get(`${config.serverURL}/auth/invoiceData/getAll?sortBy=${sortBy}&sortOrder=${order}&pageSize=10&page=${pageNumber || 1}`)
        .then((res)=>{
            setData(res.data.data);
            console.log(res.data.data);
            setModalOpen2(false);
        })
        axios.get(`${config.serverURL}/auth/invoiceData/count`)
        .then((res)=>{
            setRecords(res.data.data);
        })
    }

    const [users,setUsers] = useState([]);

    function getData(pageNumber){
        if(!localStorage.getItem("id")) navigate("/");
        console.log(pageNumber);
        axios.get(`${config.serverURL}/auth/invoiceData/getAll?sortBy=createdAt&sortOrder=descending&pageSize=10&page=${pageNumber || 1}`)
        .then((res)=>{
            setData(res.data.data);
            console.log(res.data.data);
        })

        axios.get(`${config.serverURL}/auth/automationConfig/getAll`)
        .then((res)=>{
            setUsers(res.data.data);
            console.log(res.data.data);
        })

        axios.get(`${config.serverURL}/auth/invoiceData/count`)
        .then((res)=>{
            setRecords(res.data.data);
        })
    }

    useEffect(()=>{
        getData();
    },[]);
    return ( !localStorage.getItem("id") ? navigate('/') :
        <>
            <section>
                <div className="row">
                    <Overlay configs={animate} isOpen={modalOpened4} closeOverlay={closeOverlay}>
                        {modalOpened4 && <InvoiceInfo invoiceInfo={selectedInvoice}></InvoiceInfo> }
                    </Overlay>
                    <Sidebar></Sidebar>
                    <div className="col-lg-11 col-10">
                        <div className="row upperhead">
                            <div className="col-4">
                                <p className="invoice-tracker"><span className="invoice">INVOICE</span> TRACKER</p>
                                <p className="tracker">Tracker to monitor the status of all the invoices</p>
                            </div>
                            <div className="col-4"></div>
                            <div className="col-1"></div>
                            <div className="col-2">
                                <p className="newinvoice" onClick={enableForm}><img className="newinvoiceimg" src="images/Group 96.png"/> New Invoice</p>
                                <Overlay configs={animate} isOpen={modalOpened} closeOverlay={closeOverlay} >
                                    <>
                                    <div className="formadj">
                                        <div className="row">
                                            <div className="col-6">
                                                {img?<img className="invoicepdf" src={`images/${img}`} />:<p className="fileSelection">No File Selected</p>}
                                            </div>
                                            <div className="col-6">
                                                <p className="information">CREATE <span className="bolding">INVOICE</span></p>
                                                <div className="row">
                                                    <div className="col-11">
                                                        <div className="selectfile">
                                                            <input type="file" className="select" name="image" onChange={(e)=>setFile(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Invoice Number</label>
                                                        <input className="form" id="1" type="text" value={invoiceNo}  onChange={onChangeHandler}></input><br />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Client Name</label>
                                                        <select className="form2" id="2" name="enabled" onChange={onChangeHandler}><br />
                                                            {users.map((item)=>{
                                                                return <option className="option" value={item.name}>{item.name}</option>
                                                            })}
                                                        </select> 
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Quantity(MW)</label>
                                                        <input className="form" id="3" type="number" value={quantity} onChange={onChangeHandler}></input>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Due Date</label>
                                                        <input className="form" id="9" type="date" value={dueDate} onChange={onChangeHandler}></input><br/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Amount(INR)</label>
                                                        <input className="form" id="4" type="number" value={amount} onChange={onChangeHandler}></input>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Invoice Date</label>
                                                        <input className="form" id="5" type="text" value={moment(invoiceDate).format("MMM Do YY")}></input><br/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Verification Date</label>
                                                        <input className="form" id="6" type="date" value={verificationDate} onChange={onChangeHandler}></input>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Signature Date</label>
                                                        <input className="form" id="7" type="date" value={signatureDate}  onChange={onChangeHandler}></input><br />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Remarks</label>
                                                        <input className="form" id="8" type="text" value={remarks} onChange={onChangeHandler}></input>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="submit" className="btn1" onClick={submitHandler}>CREATE</button>
                                                    </div>
                                                </div>    
                                                </div>
                                            </div>
                                    </div>
                                    <ToastContainer></ToastContainer>
                                    </>
                                </Overlay>
                            </div>
                        </div>
                        <div className="row upperhead">
                            <div className="col-5">
                                <input id="10" className="search" type="text"  placeholder="Search" onChange={onChangeHandler} onKeyUp={event => event.key === "Enter" ? applySearch() : null}/>
                            </div>
                            <div className="col-1" onClick={() => setModalOpen1(true)}>
                                <img className="filterimg" src="images/Vector.svg" />
                                <p className="filter">FILTER</p>
                            </div>
                            {modalOpen1 && 
                                <div className="modalBackground">
                                <div className="modalContainer">
                                  <div className="title">
                                    <p>FILTERS</p>
                                  </div>
                                  <div className="row">
                                      <div className="col-12">
                                          <p className="ascending">Status</p>
                                      </div>
                                  </div>
                                  <div className="row">
                                        <div className="col-1">
                                            {pending ? <input id="1" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" checked="true"/>:<input id="1" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox"/>}
                                        </div>
                                        <div className="col-3">
                                            <p className="ascending">Pending</p>
                                        </div>
                                        <div className="col-1">
                                            {verified ? <input id="2" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" checked="true"/> : <input id="2" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox"/>}
                                        </div>
                                        <div className="col-3">
                                            <p className="ascending">Verified</p>
                                        </div>     
                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            {rejected ? <input id="4" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" checked="true"/> : <input id="4" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox"/>}
                                        </div>
                                        <div className="col-3">
                                            <p className="ascending">Rejected</p>
                                        </div>
                                        <div className="col-1">
                                            {signed ? <input id="3" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" checked="true"/> : <input id="3" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox"/>}
                                        </div>
                                        <div className="col-3">
                                            <p className="ascending">Signed</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="ascending">Created Date</p>
                                        </div>
                                        <div className="col-12">
                                            {createDate ? <input id="1" className="filterform" type="date" onChange={onChangeHandler1} value={createDate}/> : <input id="1" className="filterform" type="date" onChange={onChangeHandler1}/>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="ascending">Verified Date</p>
                                        </div>
                                        <div className="col-12">
                                            {verifyDate ? <input id="2"  className="filterform" type="date" onChange={onChangeHandler1} value={verifyDate}/> : <input id="2"  className="filterform" type="date" onChange={onChangeHandler1}/>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="ascending">Signed Date</p>
                                        </div>
                                        <div className="col-12">
                                            {signDate ? <input id="3"  className="filterform" type="date" onChange={onChangeHandler1} value={signDate}/> : <input id="3"  className="filterform" type="date" onChange={onChangeHandler1}/>}
                                        </div>
                                    </div>
                                  <div className="footer">
                                    <button
                                      onClick={() => {
                                        setModalOpen1(false);
                                      }}
                                      id="cancelBtn"
                                    >
                                      Cancel
                                    </button>
                                    <button onClick={()=>{
                                        applyFilters();
                                    }}
                                    >Apply</button>
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="col-1" onClick={() => setModalOpen2(true)}>
                                <img className="filterimg2" src="images/Group 76.svg" />
                                <p className="filter">SORT</p>
                            </div>
                            {modalOpen2 &&
                                <div className="modalBackground">
                                    <div className="modalContainer">
                                        <div className="title">
                                          <p>SORT BY</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="ascending">Created Date</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                {sortBy=="createdAt" && order=="ascending" ? <input id="11" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="ascending" checked="true"/>: <input id="11" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="ascending"/>}
                                            </div>
                                            <div className="col-3">
                                                <p className="ascending">Ascending</p>
                                            </div>
                                            <div className="col-1">
                                                {sortBy=="createdAt"&& order=="descending" ? <input id="12" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="descending" checked="true"/> : <input id="12" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="descending"/>}
                                            </div>
                                            <div className="col-3">
                                                <p className="ascending">Descending</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="ascending">Verified Date</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                {sortBy=="verificationDate" && order=="ascending" ? <input id="13" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="ascending" checked="true"/> : <input id="13" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="ascending"/>}
                                            </div>
                                            <div className="col-3">
                                                <p className="ascending">Ascending</p>
                                            </div>
                                            <div className="col-1">
                                                {sortBy=="verificationDate" && order=="descending" ?<input id="14" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="descending" checked="true"/> : <input id="14" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="descending"/>}
                                            </div>
                                            <div className="col-3">
                                                <p className="ascending">Descending</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="ascending">Signed Date</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                {sortBy=="signDate" && order=="ascending" ? <input id="15" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="ascending" checked="true"/> : <input id="15" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="ascending"/>}
                                            </div>
                                            <div className="col-3">
                                                <p className="ascending">Ascending</p>
                                            </div>
                                            <div className="col-1">
                                                {sortBy=="signDate" && order=="descending" ? <input id="16" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="descending" checked="true"/> :  <input id="16" onChange={(e)=>setCheckBox(e)} className="checkbox" type="checkbox" value="descending"/>}
                                            </div>
                                            <div className="col-3">
                                                <p className="ascending">Descending</p>
                                            </div>
                                        </div>
                                        <div className="footer">
                                    <button
                                          onClick={() => {
                                            setModalOpen2(false);
                                          }}
                                          id="cancelBtn"
                                        >
                                          Cancel
                                        </button>
                                        <button onClick={()=>applySort()}>Apply</button>
                                    </div>
                                    </div>
                                </div>
                            }
                            <div className="col-1"></div>
                            <div className="col-4">
                                <p className="showingrecords">Showing Records...{records}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-12 setheight2">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>STATUS</th>
                                            <th>INVOICE NO</th>
                                            <th>CLIENT NAME</th>
                                            <th>QUANTITY(MW)</th>
                                            <th>AMOUNT(INR)</th>
                                            <th>INVOICE DATE</th>
                                            <th>DUE DATE</th>
                                            <th>CREATED BY</th>   
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {data
                                        // .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                        .map((item,index)=>{
                                            console.log(item);
                                            return <tr key={index}>
                                                <td>{item.status=="verified"? <img src="images/Ellipse 4.svg" width="20%"/>: item.status=="rejected" ? <img src="images/Ellipse 1.svg" width="20%"/> : item.status=="signed" ? <img src="images/Ellipse 2.svg" width="20%"/> : <img src="images/Ellipse 3.svg" width="20%"/>}</td>
                                                <td className="invoiceNo" onClick={()=>getInvoiceInfoForm(item)}>{item.invoiceNo}</td>
                                                <td>{item.clientName}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.amount}</td>
                                                <td>{moment(item.invoiceDate).format("MMM Do YY")}</td>
                                                <td>{item.dueDate?moment(item.dueDate).format("MMM Do YY"):"-"}</td>
                                                <td>{item.createdBy ? item.createdBy.userName : null}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>    
                                </table>  
                            </div>
                            
                        </div>
                        <div className="col-11 pagingcenter">
                            <Pagination
                            currentPage={currentPage}
                            totalItems={records}
                            itemsPerPage={pagePostsLimit}
                            onPageChange={(pageNumber) => handlePagination(pageNumber)}
                            pageNeighbours={2}
                            />
                        </div>
                    </div>
                </div>
                
            </section>
        </>
    );
}

export default Dashboard;