import React from "react";
import {BrowserRouter , Routes , Route } from 'react-router-dom';
import Automation_Config_Dashboard from "./components/automation-config-dashboard";
import Dashboard from "./components/dashboard";
import Login from "./components/login";

function App() {
  return (
    <BrowserRouter>
      <Routes>
         <Route path="/" element={<Login></Login>}/>
         <Route path="/dashboard" element={<Dashboard></Dashboard>} />
         <Route path="/automation_dashboard" element={<Automation_Config_Dashboard></Automation_Config_Dashboard>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
