import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../config";

function Login(){

    const navigate = useNavigate();
    console.log('<<<< token', localStorage.getItem("token"));
    useEffect(()=>{
        if(localStorage.getItem("token")) navigate('/dashboard');
    })
    const [username,setUserName] = useState("");
    const [password,setPassword] = useState("");
    
    const handleOnChange1 = (e)=>{
        const value = e.target.value;
        if(e.target.id=="name")
        {
            setUserName(value);
        }
        if(e.target.id=="password")
        {
            setPassword(value);
        }
    }

    function checkFields(){
        if(username && password) return true;
        return false;
    }

    async function handleSubmit(){
        if(checkFields())
        {
            const res = await fetch(`${config.serverURL}/invoiceData/user/login`,{
                method : "POST",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({
                    username : username,
                    password : password
                })
            })

            const data = await res.json();
            console.log(data);
            if(data.statusCode==200)
            {
                localStorage.setItem("token" , data.data.token);
                localStorage.setItem("id" , data.data.id);
                navigate("/dashboard")
            }
            if(data.statusCode==400)
            {
                toast("Invalid Username/Passwords",{
                    position:"bottom-right",
                    type:"error"
                })
            }
        }
        else
        {
            toast("Fill Out Your Fields",{
                position:"bottom-right",
                type:"warning"
            })
        }
    }

    return (<section>
        <div className="row">
            <div className="col-12">
                <img className="logo" src="images/instinct-infra-logo.png" />
            </div>
        </div>
        <div className="row">
            <div className="col-12 loginbg">
                <p className="admin"><span className="redadmin">ADMIN</span> LOGIN</p>
                <p className="instruction">Enter your email and password to access to portal</p>
                <input required={true} type="text" id="name"  placeholder="Email" onChange={handleOnChange1}></input><br />
                <input required={true} type="password" id="password" placeholder="Password" onChange={handleOnChange1}></input><br />
                <button className="btn" onClick={handleSubmit}>LOGIN <img src="images/arrow.png"/></button>
            </div>
        </div>
        <ToastContainer></ToastContainer>
    </section>)
}

export default Login;