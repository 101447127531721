import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../config";

function AutomationInfo(props){

    console.log(props.automationInfo);
    const [identifier,setIdentifier] = useState(props.automationInfo.identifier);
    const [name,setName] = useState(props.automationInfo.name);
    const [enabled,setEnabled] = useState(props.automationInfo.enabled);
    const [tags1,setTags1] = useState(props.automationInfo.to);
    const [whatsAppEnabled,setWhatsAppEnabled] = useState(props.automationInfo.wpEnabled);
    const [whatsAppGroupId,setWhatsAppGroupId] = useState(props.automationInfo.wpGroupId);
    const [includeZero,setIncludeZero] = useState(props.automationInfo.wpOptions.includeZero);
    const [includeNonZero,setIncludeNonZero] = useState(props.automationInfo.wpOptions.includeNonZero);
    const [tags,setTags] = useState(props.automationInfo.cc);
    const [stateName,setStateName] = useState(props.automationInfo.stateName || "")
    const [gstIn,setGstIN] = useState(props.automationInfo.gstIn || "")
    const [gstStateCode,setGstStateCode] = useState(props.automationInfo.gstStateCode || "")
    const [portfolioNo, setPortfolioNo] = useState(props.automationInfo.portfolioNo || "");
    const [isGstEnabled,setIsGstEnabled] = useState(props.automationInfo.isGstEnabled || false)
    const removeTags = indexToRemove => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};

    const removeTags1 = indexToRemove => {
		setTags1([...tags1.filter((_, index) => index !== indexToRemove)]);
	};
	const addTags = event => {
		if (event.target.value !== "") {
			setTags([...tags, event.target.value]);
			event.target.value = "";
		}
	};

    const addTags1 = event => {
		if (event.target.value !== "") {
			setTags1([...tags1, event.target.value]);
			event.target.value = "";
		}
	};

    const onChangeHandler = (e)=>{
        const value = e.target.value;
        console.log(value)
        if(e.target.id=="1") setIdentifier(value);
        if(e.target.id=="2") setName(value);
        if(e.target.id=="3") setEnabled(value);
        if(e.target.id=="5") setWhatsAppEnabled(value==="true");
        if(e.target.id=="6") setWhatsAppGroupId(value);
        if(e.target.id=="7") setIncludeZero(value==="true");
        if(e.target.id=="8") setIncludeNonZero(value);
        if(e.target.id=="10") setStateName(value);
        if(e.target.id=="11") setGstIN(value);
        if(e.target.id=="12") setGstStateCode(value);
        if(e.target.id=="13") setPortfolioNo(value);
        if(e.target.id=="14") setIsGstEnabled(value==="true");
    }

    const submitHandler = ()=>{
        axios
          .patch(
            `${config.serverURL}/auth/automationConfig/${props.automationInfo._id}/update`,
            {
              identifier: identifier,
              name: name,
              enabled: enabled,
              to: tags1,
              cc: tags,
              wpEnabled: whatsAppEnabled,
              wpGroupId: whatsAppGroupId,
              wpOptions: {
                includeZero: includeZero,
                includeNonZero: includeNonZero,
              },
              stateName: stateName,
              gstIn: gstIn,
              gstStateCode: gstStateCode,
              portfolioNo: portfolioNo,
              isGstEnabled:isGstEnabled
            }
          )
          .then((res) => {
            if (res.data.statusCode == 200) {
              toast("Sucessfully Submitted", {
                position: "bottom-center",
                type: "success",
              });
            }
            if (res.data.statusCode == 400) {
              toast("Please Fill Out All Fields", {
                position: "bottom-center",
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast(err.response.data.message, {
              position: "bottom-center",
              type: "error",
            });
          });
    }
    return (
      <>
        <div className="formadj">
          <div className="row">
            <div className="col-12">
              <p className="information">
                AUTOMATION <span className="bolding">INFO</span>
              </p>
              <div className="row">
                <div className="col-6">
                  <label>Identifier Name</label>
                  <input
                    className="form"
                    id="1"
                    type="text"
                    value={identifier}
                    onChange={onChangeHandler}
                  ></input>
                  <br />
                </div>
                <div className="col-6">
                  <label>Name</label>
                  <input
                    className="form"
                    id="2"
                    type="text"
                    value={name}
                    onChange={onChangeHandler}
                  ></input>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label>Enabled</label>
                  <select value={enabled.toString()} 
                  onChange={onChangeHandler}
                  className="form" id="3" name="enabled">
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </select>
                </div>
                <div className="col-12">
                  <label>To</label>
                  <div className="tags-input ">
                    <ul id="tags">
                      {tags1.map((to, index) => (
                        <li key={index} className="tag">
                          <span className="tag-title">{to}</span>
                          <span
                            className="tag-close-icon"
                            onClick={() => removeTags1(index)}
                          >
                            x
                          </span>
                        </li>
                      ))}
                    </ul>
                    <input
                      className="input23"
                      type="text"
                      onKeyUp={(event) =>
                        event.key === "Enter" ? addTags1(event) : null
                      }
                      placeholder="Press enter to add emails"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Cc</label>
                  <div className="tags-input ">
                    <ul id="tags">
                      {tags.map((tag, index) => (
                        <li key={index} className="tag">
                          <span className="tag-title">{tag}</span>
                          <span
                            className="tag-close-icon"
                            onClick={() => removeTags(index)}
                          >
                            x
                          </span>
                        </li>
                      ))}
                    </ul>
                    <input
                      className="input23"
                      type="text"
                      onKeyUp={(event) =>
                        event.key === "Enter" ? addTags(event) : null
                      }
                      placeholder="Press enter to add emails"
                    />
                  </div>
                </div>
                <br />
              </div>
              <div className="row">
                <div className="col-6">
                  <br />
                  <label>WhatsApp Enabled</label>
                  <select value={whatsAppEnabled.toString()}
                  onChange={onChangeHandler}
                   className="form" id="5" name="waEnabled">
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </select>
                </div>
                <div className="col-6">
                  <br />
                  <label>WhatsApp Group Id</label>
                  <input
                    className="form"
                    id="6"
                    type="text"
                    value={whatsAppGroupId}
                    onChange={onChangeHandler}
                  ></input>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label>Include Zero</label>
                  <select value={includeZero.toString()} 
                  onChange={onChangeHandler}
                  className="form" id="7" name="includeZero">
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </select>
                </div>
                <div className="col-6">
                  <label>Include Non-Zero</label>
                  <select
                    className="form"
                    id="8"
                    name="includeNonZero"
                    value={includeNonZero.toString()}
                    onChange={onChangeHandler}
                  >
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </select>
                  <br />
                </div>
              </div>
              <div className="row">
                {" "}
                {/* added by self */}
                <div className="col-6">
                  <label>State Name</label>
                  <input
                    type="text"
                    className="form"
                    id="10"
                    value={stateName}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="col-6">
                  <label>GstIN</label>
                  <input
                    type="text"
                    className="form"
                    id="11"
                    value={gstIn}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="col-6">
                  <label>GST State Code</label>
                  <input
                    type="text"
                    className="form"
                    id="12"
                    value={gstStateCode}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="col-6">
                  <label>Portfolio Number</label>
                  <input
                    type="text"
                    className="form"
                    id="13"
                    value={portfolioNo}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="col-6">    {/*For GST Enabled */}
                  <label>Is GST Enabled</label>
                  <select onChange={onChangeHandler} className="form" id="14" name="GSTenabled" value={isGstEnabled.toString()}>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn1"
                    onClick={submitHandler}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer></ToastContainer>
      </>
    );
}

export default AutomationInfo;