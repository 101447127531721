import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';
// import Overlay from "react-overlay-component";
import Sidebar from "./sidebar";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Newautomation from "./newautomation";
import AutomationInfo from "./automationinfo";
import config from "../config";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Automation_Config_Dashboard(){

    const [data,setData] = useState([]);
    const [records,setRecords] = useState(0);
    const [showForm,setShowForm] = useState(false);
    const [currentPage,setCurrentPage]=useState(1);
    const [pagePostsLimit,setpageSize] = useState(10);
    const [modalOpened,setmodalOpened] = useState(false);
    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpened3,setmodalOpened3] = useState(false);
    const [modalOpened4,setmodalOpened4] = useState(false);
    const[selectedInvoice,setSelectedInvoice] = useState({});
    const animate = true;
    const [search,setSearch] = useState("");
    const navigate = useNavigate();

    const [identifier,setIdentifier] = useState("");
    const [name,setName] = useState("");
    const [to,setTo] = useState("");
    const [whatsAppEnabled,setWhatsAppEnabled] = useState(false);
    const [whatsAppGroupId,setWhatsAppGroupId] = useState("");
    const [includeZero,setIncludeZero] = useState(false);
    const [includeNonZero,setIncludeNonZero] = useState(false);
    const [tags,setTags] = useState([]);
    const [tags1,setTags1] = useState([]);
     const [enabled,setEnabled] = useState(false);
    const [wpEnabled,setWpEnabled] = useState(false);  
     const [stateName, setStateName] = useState("");
    const [gstIn, setGstIN] = useState("");
    const [gstStateCode, setGstStateCode] = useState("");
    const [portfolioNo, setPortfolioNo] = useState("");
    const [isGstEnabled, setIsGstEnabled] = useState(false);

    var whatsAppOptions = {};

    const removeTags = indexToRemove => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};

    const removeTags1 = indexToRemove => {
		setTags1([...tags1.filter((_, index) => index !== indexToRemove)]);
	};

    const addTags1 = event => {
        console.log(event.target.value);
		if (event.target.value !== "") {
			setTags1([...tags1, event.target.value]);
			event.target.value = "";
		}
	};

	const addTags = event => {
		if (event.target.value !== "") {
			setTags([...tags, event.target.value]);
			event.target.value = "";
		}
	};

    const onChangeHandler = (e)=>{
        const value = e.target.value;
        if(e.target.id=="1") setIdentifier(value);
        if(e.target.id=="2") setName(value);
        if(e.target.id=="3") setEnabled(value==="true");
        if(e.target.id=="9") setTo(value);
        if(e.target.id=="5") setWhatsAppEnabled(value ==="true");
        if(e.target.id=="6") setWhatsAppGroupId(value);
        if(e.target.id=="7") setIncludeZero(value ==="true");
        if(e.target.id=="8") setIncludeNonZero(value ==="true");
        if(e.target.id=="10") {
            if(value==""){
                getData();
            }
            setSearch(value);
            applySearch();

        }
         if (e.target.id == "stateName") setStateName(value);
        if(e.target.id=="11") setGstIN(value);
        if(e.target.id=="12") setGstStateCode(value);
        if(e.target.id=="13") setPortfolioNo(value.toString());
        if(e.target.id=="14") setIsGstEnabled(value==="true");
    }

    function submitHandler(){
        whatsAppOptions = {
            includeZero : includeZero,
            includeNonZero : includeNonZero
        }
        axios.post(`${config.serverURL}/auth/automationConfig/create`,{
            identifier : identifier,
            name : name,
            enabled : enabled,
            to : to,
            cc : tags,
            wpEnabled : whatsAppEnabled,
            wpGroupId : whatsAppGroupId,
            wpOptions : {
                includeZero : includeZero,
                includeNonZero : includeNonZero
            },
               stateName: stateName,
              gstIn: gstIn,
              gstStateCode: +gstStateCode,
              portfolioNo: portfolioNo,
              isGstEnabled:isGstEnabled
        })
        .then((res)=>{
            if(res.data.statusCode==200)
            {
                toast("Sucessfully Submitted",{
                    position:"bottom-center",
                    type:"success"
                });
            }
            if(res.data.statusCode==400)
            {
                toast("Please Fill Out All Fields",{
                    position:"bottom-center",
                    type:"success"
                });
            }
        })
        .catch((err)=>{
            console.log(err);
            toast(err.response.data.message,{
                position:"bottom-center",
                type:"error"
            })
        })
    }


    const setCheckBox = (e)=>{
        if(e.target.id=="1") setEnabled(true);
        if(e.target.id=="2") setEnabled(false);
        if(e.target.id=="3") setWpEnabled(true);
        if(e.target.id=="4") setWpEnabled(false);
    }

    const enableForm=()=>{
        setmodalOpened(true);
        console.log(modalOpened);
    }

    function applySearch(){
        axios.get(`${config.serverURL}/auth/automationConfig/search/${search}`)
        .then((res)=>{

            // console.log(res)
            setData(res.data.data);
        })
    }

    function applyFilter(){
        axios.get(`${config.serverURL}/auth/automationConfig/getAll?enabled=${enabled}&wpEnabled=${wpEnabled}`)
        .then((res)=>{
            setData(res.data.data);
            console.log(res.data.data);
            setModalOpen1(false);
            setEnabled("");
            setWpEnabled("");
        })
        axios.get(`${config.serverURL}/auth/automationConfig/count?enabled=${enabled}&wpEnabled=${wpEnabled}`)
        .then((res)=>{
            setRecords(res.data.data);
        })
    }

    const closeOverlay = ()=>{
        setmodalOpened(false);
        // setmodalOpened2(false);
        setmodalOpened3(false);
        setmodalOpened4(false);
    }

    const getAutomationInfo=(obj)=>{
        console.log(obj);
        setShow1(true);
        setmodalOpened4(true);
        console.log(modalOpened4);
        setSelectedInvoice(obj);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handlePagination = (pageNumber)=>{
        setCurrentPage(pageNumber);
        getData(pageNumber);
    }
    function getData(pageNumber){
        if(!localStorage.getItem("id")) navigate("/");
        axios.get(`${config.serverURL}/auth/automationConfig/getAll?pageSize=10&page=${pageNumber || 1}`)
        .then((res)=>{
            setData(res.data.data);
            console.log(res.data.data);
        })
        axios.get(`${config.serverURL}/auth/automationConfig/count`)
        .then((res)=>{
            setRecords(res.data.data);
        })
    }

    useEffect(()=>{
        getData();
    },[]);
    return (
      <>
        {
          <div className="row">
            <Sidebar></Sidebar>
            <Modal show={show1} onHide={handleClose1}>
              <Modal.Body>
                <AutomationInfo
                  automationInfo={selectedInvoice}
                ></AutomationInfo>
              </Modal.Body>
            </Modal>
            <div className="col-lg-11 col-10">
              <div className="row upperhead">
                <div className="col-4">
                  <p className="invoice-tracker">
                    <span className="invoice">AUTOMATION</span> TRACKER
                  </p>
                  <p className="tracker1">
                    Tracker to monitor the status of all the Automation
                  </p>
                </div>
                <div className="col-5"></div>
                <div className="col-3">
                  <p className="export" onClick={handleShow}>
                    <img className="newinvoiceimg" src="images/Group 96.png" />{" "}
                    New Automation
                  </p>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>CREATE AUTOMATION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="formadj">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-6">
                                <label>Identifier Name</label>
                                <input
                                  className="form"
                                  id="1"
                                  type="text"
                                  onChange={onChangeHandler}
                                ></input>
                                <br />
                              </div>
                              <div className="col-6">
                                <label>Name</label>
                                <input
                                  className="form"
                                  id="2"
                                  type="text"
                                  onChange={onChangeHandler}
                                ></input>
                                <br />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <label>Enabled</label>
                                <select
                                  value={enabled.toString()}
                                  className="form2"
                                  id="3"
                                  name="enabled"
                                  onChange={onChangeHandler}
                                >
                                  <option value="false">NO</option>
                                  <option value="true">YES</option>
                                </select>
                              </div>
                              <div className="col-12">
                                <label>To</label>
                                <div className="tags-input ">
                                  <ul id="tags">
                                    {tags1.map((to, index) => (
                                      <li key={index} className="tag">
                                        <span className="tag-title">{to}</span>
                                        <span
                                          className="tag-close-icon"
                                          onClick={() => removeTags1(index)}
                                        >
                                          x
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                  <input
                                    className="input23"
                                    type="text"
                                    onKeyUp={(event) =>
                                      event.key === "Enter"
                                        ? addTags1(event)
                                        : null
                                    }
                                    placeholder="Press enter to add emails"
                                  />
                                </div>
                                <br />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <label>Cc</label>
                                <div className="tags-input ">
                                  <ul id="tags">
                                    {tags.map((tag, index) => (
                                      <li key={index} className="tag">
                                        <span className="tag-title">{tag}</span>
                                        <span
                                          className="tag-close-icon"
                                          onClick={() => removeTags(index)}
                                        >
                                          x
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                  <input
                                    className="input23"
                                    type="text"
                                    onKeyUp={(event) =>
                                      event.key === "Enter"
                                        ? addTags(event)
                                        : null
                                    }
                                    placeholder="Press enter to add emails"
                                  />
                                </div>
                              </div>
                              <br />
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <br />
                                <label>WhatsApp Enabled</label>
                                <select
                                  value={whatsAppEnabled.toString()}
                                  className="form2"
                                  id="5"
                                  name="waEnabled"
                                  onChange={onChangeHandler}
                                >
                                  <option value="false">NO</option>
                                  <option value="true">YES</option>
                                </select>
                              </div>
                              <div className="col-6">
                                <br />
                                <label>WhatsApp Group Id</label>
                                <input
                                  className="form"
                                  id="6"
                                  type="text"
                                  onChange={onChangeHandler}
                                ></input>
                                <br />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <label>Include Zero</label>
                                <select
                                  className="form2"
                                  value={includeZero.toString()}
                                  id="7"
                                  name="includeZero"
                                  onChange={onChangeHandler}
                                >
                                  <option value="false">NO</option>
                                  <option value="true">YES</option>
                                </select>
                              </div>
                              <div className="col-6">
                                <label>Include Non-Zero</label>
                                <select
                                  className="form2"
                                  value={includeNonZero.toString()}
                                  id="8"
                                  name="includeNonZero"
                                  onChange={onChangeHandler}
                                >
                                  <option value="false">NO</option>
                                  <option value="true">YES</option>
                                </select>{" "}
                                <br />
                              </div>
                            </div>
                            <div className="row">
                              {" "}
                              {/* added by self */}
                              <div className="col-6">
                                <label>State Name</label>
                                <input
                                  type="text"
                                  className="form"
                                  id="stateName"
                                  value={stateName}
                                  onChange={onChangeHandler}
                                />
                              </div>
                              <div className="col-6">
                                <label>GstIN</label>
                                <input
                                  type="text"
                                  className="form"
                                  id="11"
                                  value={gstIn}
                                  onChange={onChangeHandler}
                                />
                              </div>
                              <div className="col-6">
                                <label>GST State Code</label>
                                <input
                                  type="text"
                                  className="form"
                                  id="12"
                                  value={gstStateCode}
                                  onChange={onChangeHandler}
                                />
                              </div>
                              <div className="col-6">
                                <label>Portfolio Number</label>
                                <input
                                  type="text"
                                  className="form"
                                  id="13"
                                  value={portfolioNo}
                                  onChange={onChangeHandler}
                                />
                              </div>
                              <div className="col-6">
                                {" "}
                                {/*For GST Enabled */}
                                <label>Is GST Enabled</label>
                                <select
                                  onChange={onChangeHandler}
                                  className="form"
                                  id="14"
                                  name="GSTenabled"
                                  value={isGstEnabled.toString()}
                                >
                                  <option value="true">YES</option>
                                  <option value="false">NO</option>
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <button
                                  type="submit"
                                  className="btn1"
                                  onClick={submitHandler}
                                >
                                  CREATE
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ToastContainer></ToastContainer>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
              <div className="row upperhead">
                <div className="col-5">
                  <input
                    id="10"
                    className="search2"
                    type="text"
                    placeholder="Search"
                    onChange={onChangeHandler}
                    onKeyUp={(event) =>
                      event.key === "Enter" ? applySearch() : null
                    }
                  />
                </div>
                <div className="col-1" onClick={() => setModalOpen1(true)}>
                  <img className="filterimg" src="images/Vector.svg " />
                  <p className="filter">FILTER</p>
                </div>
                {modalOpen1 && (
                  <div className="modalBackground">
                    <div className="modalContainer">
                      <div className="title">
                        <p>FILTER</p>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="ascending">Enabled</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <input
                            id="1"
                            onChange={(e) => setCheckBox(e)}
                            className="checkbox"
                            type="checkbox"
                          />
                        </div>
                        <div className="col-3">
                          <p className="ascending">YES</p>
                        </div>
                        <div className="col-1">
                          <input
                            id="2"
                            onChange={(e) => setCheckBox(e)}
                            className="checkbox"
                            type="checkbox"
                          />
                        </div>
                        <div className="col-3">
                          <p className="ascending">NO</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="ascending">WhatsApp Enabled</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <input
                            id="3"
                            onChange={(e) => setCheckBox(e)}
                            className="checkbox"
                            type="checkbox"
                          />
                        </div>
                        <div className="col-3">
                          <p className="ascending">YES</p>
                        </div>
                        <div className="col-1">
                          <input
                            id="4"
                            onChange={(e) => setCheckBox(e)}
                            className="checkbox"
                            type="checkbox"
                          />
                        </div>
                        <div className="col-3">
                          <p className="ascending">NO</p>
                        </div>
                      </div>
                      <div className="footer">
                        <button
                          onClick={() => {
                            console.log("clicked");
                            setModalOpen1(false);
                          }}
                          id="cancelBtn"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            applyFilter();
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-1"></div>
                <div className="col-5">
                  <p className="showingrecords2">Showing Records...{records}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-12 setheight">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>IDENTIFIER</th>
                        <th>NAME</th>
                        <th>EMAIL ENABLED</th>
                        <th>WHATSAPP ENABLED</th>
                        <th>WHATSAPP NOTIFICATION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        // .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="invoiceNo"
                                onClick={() => getAutomationInfo(item)}
                              >
                                {item.identifier}
                              </td>
                              <td>{item.name}</td>
                              <td>{item.enabled ? "YES" : "NO"}</td>
                              <td>{item.wpEnabled ? "YES" : "NO"}</td>
                              <td>
                                {item.wpOptions.includeZero &&
                                item.wpOptions.includeNonZero
                                  ? "Zero,NonZero"
                                  : item.wpOptions.includeZero
                                  ? "Zero"
                                  : "NonZero"}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-11 pagingcenter">
                <Pagination
                  currentPage={currentPage}
                  totalItems={records}
                  itemsPerPage={pagePostsLimit}
                  onPageChange={(pageNumber) => handlePagination(pageNumber)}
                  pageNeighbours={2}
                />
              </div>
            </div>
          </div>
        }
      </>
    );
}

export default Automation_Config_Dashboard;