import axios from "axios";
import React, { useEffect , useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AppBar from '@mui/material/AppBar';
import config from "../config";
import Avatar from 'react-avatar';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
function InvoiceInfo(props){
    console.log(props);
    console.log(props.invoiceInfo._id);
    const [value,setValue] = useState(0);
    const[invoiceNo,setInvoiceNo] = useState(props.invoiceInfo.invoiceNo);
    const[clientName,setClientName] = useState(props.invoiceInfo.clientName);
    const[quantity,setQuantity] = useState(props.invoiceInfo.quantity);
    const[amount,setAmount] = useState(props.invoiceInfo.amount);
    const[invoiceDate,setInvoiceDate] = useState(props.invoiceInfo.invoiceDate);
    const[verificationDate,setVerificationDate] = useState(props.invoiceInfo.verificationDate);
    const[signatureDate,setSignatureDate] = useState(props.invoiceInfo.signatureDate);
    const[remarks,setRemarks] = useState(props.invoiceInfo.remarks);
    const[invoiceHistoryData,setinvoiceHistoryData] = useState([{}]);
    const[status,setStatus] = useState(props.invoiceInfo.status);

    const onChangeHandler = (e)=>{
        const value = e.target.value;
        if(e.target.id=="1") setInvoiceNo(value);
        if(e.target.id=="2") setClientName(value);
        if(e.target.id=="3") setQuantity(value);
        if(e.target.id=="4") setAmount(value);
        if(e.target.id=="5") setInvoiceDate(moment(value).format());
        if(e.target.id=="6") setVerificationDate(moment(value).format());
        if(e.target.id=="7") setSignatureDate(moment(value).format());
        if(e.target.id=="8") setRemarks(value);
    }


    function submitHandler(actionType){
        if(actionType=="verified"){
            axios.patch(`${config.serverURL}/auth/invoiceData/`+props.invoiceInfo._id+`/update`,{
                status : actionType,
                verifiedDate : moment().subtract(10, 'days').calendar()
             },{headers:{"token" : localStorage.getItem("token"),"userId":localStorage.getItem("id")}})
             .then((res)=>{
                 if(res.data.statusCode==200)
                 {
                     toast("Sucessfully Submitted",{
                         position:"bottom-center",
                         type:"success"
                     });
                     setStatus(actionType);
                     getData();
                 }
             })
             .catch((err)=>{
                 console.log(err);
                 toast(err.response.data.message,{
                     position:"bottom-center",
                     type:"error"
                 })
             })
        }
        else if(actionType=="signed"){
            axios.patch(`${config.serverURL}/auth/invoiceData/`+props.invoiceInfo._id+`/update`,{
                status : actionType,
                signedDate : moment().subtract(10, 'days').calendar()
             },{headers:{"token" : localStorage.getItem("token"),"userId":localStorage.getItem("id")}})
             .then((res)=>{
                 if(res.data.statusCode==200)
                 {
                     toast("Sucessfully Submitted",{
                         position:"bottom-center",
                         type:"success"
                     });
                     setStatus(actionType);
                     getData();
                 }
             })
             .catch((err)=>{
                 console.log(err);
                 toast(err.response.data.message,{
                     position:"bottom-center",
                     type:"error"
                 })
             })
        }
        else{
            axios.patch(`${config.serverURL}/auth/invoiceData/`+props.invoiceInfo._id+`/update`,{
                status : actionType,
             },{headers:{"token" : localStorage.getItem("token"),"userId":localStorage.getItem("id")}})
             .then((res)=>{
                 if(res.data.statusCode==200)
                 {
                     toast("Sucessfully Submitted",{
                         position:"bottom-center",
                         type:"success"
                     });
                     getData();
                 }
             })
             .catch((err)=>{
                 console.log(err);
                 toast(err.response.data.message,{
                     position:"bottom-center",
                     type:"error"
                 })
             })
        }
   }

   const handleTabs = (e,val)=>{
        console.log(val);
        setValue(val);
   }

   function getData(){
        axios.get(`${config.serverURL}/auth/invoiceHistory/${props.invoiceInfo._id}/getOne`)
        .then((res)=>{
            console.log(res.data.data);
            setinvoiceHistoryData(res.data.data);
            console.log(invoiceHistoryData);
        })
        axios.get(props.invoiceInfo.invoiceUrl)
        .then(()=>{
            console.log("downlaoded");
        })
   }

    useEffect(()=>{
        getData();
    },[setinvoiceHistoryData])

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }

      

    return(
        invoiceHistoryData && <>
        <div className="formadj">
            
            <div className="row">
                <div className="col-6">
                    
                    <iframe src={props.invoiceInfo?.invoiceUrl} height="750" width="600"/>
                </div>
                <div className="col-6">
                <p className="information">INVOICE<span className="bolding"> INFO</span></p>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleTabs}>
                        <Tab label="Verification" />
                        <Tab label="Digital Signature" />
                        <Tab label="Invoice History" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <br />
                    <div className="row">
                        <div className="col-6">
                            <label>Invoice Number</label>
                            <input className="form" id="1" value={invoiceNo} type="text" onChange={onChangeHandler} readOnly></input>
                        </div>
                        <div className="col-6">
                            <label>Client Name</label>
                            <input className="form" id="2" type="text" value={clientName} onChange={onChangeHandler} readOnly></input><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label>Quantity(MW)</label>
                            <input className="form" id="3" type="number" value={quantity} onChange={onChangeHandler} readOnly></input>
                        </div>
                        <div className="col-6">
                            <label>Amount(INR)</label>
                            <input className="form" id="4" type="number" value={amount} onChange={onChangeHandler} readOnly></input><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label>Invoice Date</label>
                            <input className="form" id="5" type="text" value={moment(invoiceDate).format("MMM Do YY")} readOnly></input>
                        </div>
                        <div className="col-6">
                            <label>Remarks</label>
                            <input className="form" id="8" type="text" value={remarks} onChange={onChangeHandler} readOnly></input><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5"></div>
                        <div className="col-6">
                           {status=="verified" || status=="signed" ?<> <button  id="cancelBtn" className="btn2"  disabled>REJECT</button><button   className="btn2"  disabled>VERIFY</button></>
                             : <><button type="submit" id="cancelBtn" className="btn1" onClick={()=>submitHandler("rejected")} >REJECT</button><button type="submit"  className="btn1" onClick={()=>submitHandler("verified")}>VERIFY</button></>}
                        </div>
                    </div> 
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <br />
                    <div className="row">
                        <div className="col-6">
                            <label>Invoice Number</label>
                            <input className="form" id="1" value={invoiceNo} type="text" onChange={onChangeHandler} readOnly></input>
                        </div>
                        <div className="col-6">
                            <label>Client Name</label>
                            <input className="form" id="2" type="text" value={clientName} onChange={onChangeHandler} readOnly></input><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label>Quantity(MW)</label>
                            <input className="form" id="3" type="number" value={quantity} onChange={onChangeHandler} readOnly></input>
                        </div>
                        <div className="col-6">
                            <label>Amount(INR)</label>
                            <input className="form" id="4" type="number" value={amount} onChange={onChangeHandler} readOnly></input><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label>Invoice Date</label>
                            <input className="form" id="5" type="text" value={moment(invoiceDate).format("MMM Do YY")} readOnly></input>
                        </div>
                        <div className="col-6">
                            <label>Remarks</label>
                            <input className="form" id="8" type="text" value={remarks} onChange={onChangeHandler} readOnly></input><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6 sign">
                            {status=="signed" || status=="pending" || status=="rejected" ? <button type="submit" className="btn2" onClick={()=>submitHandler("signed")} disabled>SIGN</button> : <button type="submit" className="btn1" onClick={()=>submitHandler("signed")}>SIGN</button>}
                        </div>
                    </div> 
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <br />
                        {invoiceHistoryData.map((item)=>{
                            console.log(item);
                            return item && <> 
                                <p className="dateadj">{moment(item.createdAt).format("MMM Do YY")}</p>
                                <div className="row historyitem mb-4">
                                    
                                    <div className="col-2 mb-3">
                                        <Avatar name={item.actionBy?.userName} round={true} size={75} />
                                    </div>
                                    <div className="col-3">
                                        <p className="actionBy">{item.actionBy?.userName}</p>
                                        <label >{item.actionBy?.employeeId}</label>
                                    </div>
                                    <div className="col-4"></div>
                                    <div className="col-3">
                                        <p className="history">{item.actionType}</p>
                                    </div>
                                </div>
                            </>
                            
                        })}
                </TabPanel> 
                </div>
            </div> 
        </div>
    <ToastContainer></ToastContainer>
    </>
    );
}

function TabPanel(props){
const {children,value,index} = props

    return (<div>
        {
            value==index &&(
                <h1>{children}</h1>
            )
        }
    </div>)
}

export default InvoiceInfo;
    
