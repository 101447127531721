import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config";

function Sidebar(){

    const navigation = useNavigate();

    const gotoAutomation = ()=>{
        navigation("/automation_dashboard");
    }

    const gotoDashboard = ()=>{
        navigation("/dashboard");
    }

    const getLogout = ()=>{
        axios.post(`${config.serverURL}/auth/user/logout`,{token : localStorage.getItem("token")})
        .then((res)=>{
            console.log(res.data);
            if(res.data.statusCode===200)
            {
                localStorage.removeItem("token");
                localStorage.removeItem('id');
                return navigation("/");
            } 
        },{headers:{"token" : localStorage.getItem("token")}}) 
    }

    return(
        <div className="col-lg-1 col-2 sidebarbg">
            <div className="row">
                <div className="col-12">
                    <img className="timer" src="images/timer.png" onClick={gotoAutomation}/>
                </div>
                <div className="col-12">
                    <img className="dashboard" src="images/dashboard.png" onClick={gotoDashboard}/>
                </div>
                <div className="col-12">
                    <img className="logout" src="images/logout.png" onClick={getLogout}/>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;